import { Alert, Box, Card, CardContent, Grid, Typography } from "@mui/material"
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Loading,
  NumberField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useShowContext,
  useShowController,
} from "react-admin"
import { MoneyField } from "resources/billing/bills/MoneyField"
import { OwnerRefField } from "resources/real_estate/owners"

import { UserRefField } from "../../iam/users"
import { GuestAddButton } from "../calendar_entry_guests/GuestAddButton"
import { CalendarRefField } from "../calendars"
import { CalendarReferenceManyField } from "../calendars/CalendarReferenceManyField"
import { QuotaRefField } from "../quotas"
import { AddBillButton } from "./AddBillButton"
import { AddStatusButton } from "./AddStatusButton"
import { CalendarEntryEditAside } from "./CalendarEntryEditAside"
import { CalendarEntryForm } from "./CalendarEntryForm"
import CancelButton from "./buttons/CancelButton"
import ConfirmButton from "./buttons/ConfirmButton"
import DeclineButton from "./buttons/DeclineButton"
import { BookingRulesValidationResult } from "./components/BookingRulesValidationResult"
import EntryStatusField from "./fields/EntryStatusField"
import { ShowRelatedBookings } from "./fields/ShowRelatedBookings"
import { BookingInfoCard } from "./show/BookingInfoCard"
import { PropertyUsages } from "./components/PropertyUsages"
import React from "react"
import { LocalDate } from "@js-joda/core"
import { QuotaUsage } from "../quotas/Usage"
import { PropertyUsageList } from "../property_usages/property_usages"
import { PropertyUsageAddButton } from "../property_usages/PropertyUsageAddButton"
import { TagsListField } from "./fields/TagsListField"
import { PropertyRefField } from "resources/real_estate/properties"
import RescheduleButton from "./buttons/RescheduleButton"

const CalendarEntryShowActions = () => {
  const { record } = useShowContext()

  return (
    <TopToolbar>
      {/* <AddStatusButton /> */}
      {/* <Button color="primary" label="Reset Password" startIcon={<LockReset />} onClick={() => {}} /> */}

      <ConfirmButton record={record} />
      <CancelButton record={record} />
      <DeclineButton record={record} />
      <RescheduleButton record={record} />

      <EditButton />
    </TopToolbar>
  )
}

export const CalendarEntryShow = () => {
  const { record, isLoading } = useShowController()

  const [defaultCalendarDate, setDefaultCalendarDate] = React.useState<LocalDate | undefined>()

  const handleYearSelected = (year: number) => {
    setDefaultCalendarDate(LocalDate.of(year, 1, 1))
  }

  const handleDateSelected = (year: number, month: number, day: number) => {
    setDefaultCalendarDate(LocalDate.of(year, month, day))
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Show actions={<CalendarEntryShowActions />}>
      <TabbedShowLayout spacing={2}>
        <Tab label="Entry Details">
          <CalendarRefField />
          <TextField source="type" />
          {["special_date", "main_holiday_period"].includes(record?.type) && (
            <TextField source="entry_code" />
          )}
          {record?.type === "booking" && <TextField source="booking_type" />}
          {record?.type === "season" && <TextField source="season_type" />}
          <EntryStatusField source="current_status" />
          <OwnerRefField />
          <UserRefField />
          <TextField source="title" />

          <DateField source="start_date" />
          <DateField source="end_date" />

          {!record?.is_all_day && <TextField source="start_time" />}
          {!record?.is_all_day && <TextField source="end_time" />}

          {record?.type === "other" && <TextField source="transparency" />}

          <TagsListField label="Tags" />
          <TextField source="internal_notes" />
          <TextField source="housekeeping_notes" />

          {record?.booking_type === "external_rental" && (
            <TextField source="provider_resource_id" />
          )}
        </Tab>

        {record?.type === "booking" && (
          <Tab label="Booking Details">
            <Grid container spacing={0}>
              <Grid item xs={7}>
                <SimpleShowLayout>
                  <PropertyRefField source="property_id" />
                  <TextField source="booking_type" />
                  <QuotaRefField source="quota_id" />
                  <TextField source="title" />
                  <DateField source="start_date" label="Check-In date" />
                  <DateField source="end_date" label="Check-Out date" />
                  <NumberField source="adults_count" />
                  <NumberField source="children_count" />
                  <NumberField source="infants_count" />
                  <NumberField
                    source="requested_laundry_packages_count"
                    label="Requested Laundry Packages"
                  />
                  <NumberField source="requested_bedrooms_count" label="Requested Bedrooms" />
                  <BooleanField source="has_pets" />
                  <NumberField source="local_tourism_tax_total_count" />
                  <TextField source="contact_name" />
                  <TextField source="contact_phone" />
                  <TextField source="contact_email" />
                  <TextField source="note" label="Notes from Customer" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={5}>
                <BookingRulesValidationResult
                  validationResult={record.booking_rules_validation_result}
                />
                <PropertyUsages bookingId={record.id} />
              </Grid>
            </Grid>
          </Tab>
        )}

        <Tab label="Guests" path="guests">
          <GuestAddButton />

          <ReferenceManyField
            reference="calendar_entry_guests"
            source="id"
            target="calendar_entry_id"
            sort={{ field: "created_at", order: "DESC" }}
            label="All Guests"
          >
            <Datagrid
              rowClick="show"
              bulkActionButtons={false}
              empty={<Alert severity="info">No guests for this booking.</Alert>}
            >
              <TextField source="first_name" />
              <TextField source="last_name" />
              <TextField source="id_card_number" />
              <DateField source="birth_date" />
              <DateField source="attended_from" />
              <DateField source="attended_to" />

              <DateField source="created_at" showTime />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        {record?.type === "booking" && (
          <Tab label="Check Dates/Quota">
            <Typography variant="h5" gutterBottom component="div">
              Check Dates
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Property Calendar
                    </Typography>

                    <CalendarRefField />

                    <Box mb={2} />

                    <CalendarReferenceManyField
                      label={false}
                      source="calendar_id"
                      showDate={defaultCalendarDate}
                    />
                  </CardContent>
                </Card>

                <Box mb={2} />

                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Quota
                    </Typography>

                    <Box mb={2} />

                    <Card>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Shares in Quota
                        </Typography>

                        <ReferenceManyField
                          reference="booking_quota_shares"
                          source="quota_id"
                          target="quota_id"
                          sort={{ field: "created_at", order: "ASC" }}
                          label="Shares"
                        >
                          <Datagrid
                            bulkActionButtons={false}
                            empty={
                              <Alert severity="info">No quota shares for this quota yet.</Alert>
                            }
                          >
                            <NumberField source="share_number" />
                            <DateField source="valid_from" />
                            <DateField source="valid_to" />
                            <ShowButton />
                          </Datagrid>
                        </ReferenceManyField>
                      </CardContent>
                    </Card>

                    <Box mb={2} />

                    {!record?.quota_id ? <Loading /> : <QuotaUsage quotaId={record.quota_id} />}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={5}>
                <BookingInfoCard />

                <Box mb={2} />

                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Owners' Bookings per Period
                    </Typography>
                    <ShowRelatedBookings
                      onYearSelected={handleYearSelected}
                      onDateSelected={handleDateSelected}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Tab>
        )}

        <Tab label="Billing" path="billing">
          <AddBillButton />

          <ReferenceManyField
            reference="bills"
            source="id"
            target="booking_id"
            sort={{ field: "created_at", order: "DESC" }}
            label="All Saved Bills"
          >
            <Datagrid
              rowClick="show"
              bulkActionButtons={false}
              empty={<Alert severity="info">No bills existing for this booking.</Alert>}
            >
              <OwnerRefField source="customer_id" />
              <TextField source="unique_number" />
              <DateField source="check_in_day" />
              <DateField source="check_out_day" />
              <MoneyField source="subtotal" />
              <MoneyField source="vat" />
              <MoneyField source="total" />
              <MoneyField source="due" />

              <TextField source="internal_notes" />
              <UserRefField source="created_by" />
              <DateField source="created_at" showTime />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Status History" path="statuses">
          <AddStatusButton />
          <ReferenceManyField
            reference="calendar_entry_statuses"
            target="calendar_entry_id"
            sort={{ field: "created_at", order: "DESC" }}
            label={false}
          >
            <Datagrid bulkActionButtons={false}>
              <EntryStatusField source="status" />
              <TextField source="internal_notes" />
              <DateField source="created_at" showTime />
              <UserRefField source="created_by" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Event Settings">
          <DateField source="current_status_at" showTime />
          <TextField source="transparency" />
          <TextField source="participation_status" />
          <TextField source="attendees" />
          <TextField source="organizer" />
          <TextField source="categories" />
          <BooleanField source="is_locked" />
          <TextField source="start_timezone" />
          <TextField source="end_timezone" />
          <BooleanField source="guests_can_invite_others" />
          <BooleanField source="guests_can_see_other_guests" />
          <TextField source="status" />
        </Tab>

        <Tab label="Property Usages" path="property_usage">
          <PropertyUsageAddButton />
          <PropertyUsageList />
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const CalendarEntryCreate = () => (
  <Create redirect="show">
    <CalendarEntryForm
      isExisting={false}
      defaultValues={{ calendar_id: undefined, is_all_day: true, timezone: "Europe/Berlin" }}
    />
  </Create>
)

export const CalendarEntryEdit = () => (
  <Edit aside={<CalendarEntryEditAside />} mutationMode="pessimistic" redirect="show">
    <CalendarEntryForm isExisting />
  </Edit>
)
